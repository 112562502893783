@import 'aos-plugins/core';
@import 'aos-plugins/easing';
@import 'aos-plugins/animations';

/*
  ---------------
  aos customize animation
  ---------------
*/
.top-product__block > .top-product__info [data-aos="custom-anime"] {
  opacity: 0;
  transition-property: transform, opacity;
  transform: translate3d(0, 100px, 0);
  transition-duration: 1s;

  @media all and (max-width: 599px) {}

  &.aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;

    @media all and (max-width: 599px) {
      transition-delay: .2s;
    }
  }
}
